body, html {
    margin: 0;
    padding: 0;
    /* font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;  
    scroll-behavior: smooth; */
  }


/* Base Navbar Styling */
.nav {
    width: 100%;
    background-color:#222222;
  }
  
  /* Navbar Container */
  .navbar {
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 10rem;
    background-color:#222222;
    color:white;
  }
  
  .navbar-logo img {
    width: 100px;
    height: 80px;
  }
  
  .navbar-links {
    display: flex;
    gap: 3rem;
    align-items: center;
  }
  
  .navbar-links a {
    color:white;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    /* font-weight: 500; */
  }
  
  .navbar-links a:hover {
    /* padding: 10px 10px;
    background:linear-gradient(to bottom, #B94703, #E8A158);
    border-radius: 10px; */
    color:#B94703;
  }
  
  .download-brochure {
    padding: 0.5rem 1rem;
    background: linear-gradient(to bottom, #B94703, #E8A158);
    color: white;
    border: none;
    border-radius:50px;
    cursor: pointer;
    font-size: 20px;
    /* font-weight: 500; */
  }
  
  /* Hamburger Icon for Mobile */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
  }
  
  /* Overlay Styles for Mobile Menu */
  .overlay {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(234, 226, 226, 0.9);
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:black;
    transition: opacity 0.3s ease;
  }
  
  .overlay.open {
    display: flex;
  }
  
  .overlay-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    background: none;
    color:black;
    border: none;
    cursor: pointer;
  }
  
  .overlay-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
  
  .overlay-links a {
    color:black;
    font-size: 1.5rem;
    text-decoration: none;
  }
  
  /* Responsive Design */

  @media (max-width:1024px) {
    .navbar-links {
      display: none; /* Hide navbar links on mobile */
    }
  
    .hamburger {
      display: flex; /* Show hamburger icon */
    }
  
    .navbar {
      padding: 1rem ; /* Reduce padding further for mobile */
      justify-content: space-between;
      gap: 37.5rem;
    }
  
    .navbar-logo {
      flex: 1; /* Ensures logo stays on the left */
    }
  
    .hamburger {
      flex: 1;
      justify-content: flex-end; /* Align hamburger to the right */
     
      
    }
  }
  
  
  @media (max-width:768px) {
    .navbar-links {
      display: none; /* Hide navbar links on mobile */
    }
  
    .hamburger {
      display: flex; /* Show hamburger icon */
    }
  
    .navbar {
      padding: 1rem; /* Reduce padding further for mobile */
      justify-content: space-between;
      gap: 37.5rem;
    }
  
    .navbar-logo {
      flex: 1; /* Ensures logo stays on the left */
    }
  
    .hamburger {
      flex: 1;
      justify-content: flex-end; /* Align hamburger to the right */
     
      
    }
  }
  
  /* Mobile Screens (768px and Smaller) */
  @media (max-width: 480px) {
    .navbar-links {
      display: none; /* Hide navbar links on mobile */
    }
  
    .hamburger {
      display: flex; /* Show hamburger icon */
    }
  
    .navbar {
      padding: 1rem ; /* Reduce padding further for mobile */
      justify-content: space-between;
      gap: 13.5rem;
    }
  
    .navbar-logo {
      flex: 1; /* Ensures logo stays on the left */
    }
  
    .hamburger {
      flex: 1;
      justify-content: flex-end; /* Align hamburger to the right */
      
    }
  }

  


.privacy{
    width: 100%;
}

.policy1{
    max-width: 1520px;
    margin: 0 auto;
    padding: 0px 10rem;
    
}

.policy1 h3{
    font-size: 34px;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;

}

.policy1 h5{
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    font-family:'Mulish';
}

.policy1 h4{
    font-weight: 500;
    line-height: 34px;
    padding-left: 3rem;
    font-family:'Mulish';

}

@media(max-width:1024px){
    .policy1{
        padding: 10px 2.5rem;
    }
}


@media(max-width:768px){
    .policy1{
        padding: 10px 2.25rem;
    }
}

@media(max-width:480px){
    .policy1{
        padding: 10px 1.25rem;
    }
}



/* Footer Section */

.contact{
    width: 100%;
    background-color:#222222;
}

.footer-section {
    max-width: 1520px;
    margin: 0 auto;
    background-color:#222222;
    color:#fff;
    padding: 3rem 2rem;
    font-size: 0.9rem;
    margin-top: 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 2rem;
    text-align: left;
  }
  
  .footer-column h3, .footer-column h4 {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .footer-column p {
    color:#fff;
    margin-bottom: 1rem;
  }

  .policies{
    display: flex;
    flex-direction:row;
    gap: 20px;
    
  }

  .policies a{
    text-decoration-line: none;
    color:#fff;
  }

  .policies a:hover{
    transform: translateY(-3px);
  }

  
  .footer-phone {
    font-weight: bold;
    color:#B94703;
  }
  
  .underline {
    width: 50px;
    height: 2px;
    background-color:#B94703;
    margin: 0.5rem 0;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #333;
    padding-top: 1.5rem;
    margin-top: 2rem;
    font-size: 0.8rem;
    color:#fff;
  }
  
    /* Responsive Design */

    @media (max-width: 1024px) {
        .footer-content {
          flex-direction: column;
          text-align: center;
          font-size:24px ;
        }
      
        .footer-bottom {
          flex-direction: column;
          gap: 1rem;
        }
    
        .footer-column .underline{
            display: block;
            margin: 0 auto;
          }
      }
    
    
      @media (max-width: 768px) {
        .footer-content {
          flex-direction: column;
          text-align: center;
          font-size:24px ;
        }
      
        .footer-bottom {
          flex-direction: column;
          gap: 1rem;
        }
    
        .footer-column .underline{
          display: block;
          margin: 0 auto;
        }
    
        .policies{
          align-items: center;
          justify-content: center;
        }
      }
    
      
      /* Responsive Design */
      @media (max-width: 480px) {
        .footer-content {
          flex-direction: column;
          text-align: center;
          font-size: 16px;
        }
      
        .footer-bottom {
          flex-direction: column;
          gap: 1rem;
        }
        .footer-column .underline{
          display: block;
          margin: 0 auto;
        }
      }
      