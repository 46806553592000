/* index.css */
body, html {
  margin: 0;
  padding: 0;
  /* font-family: "Montserrat", sans-serif,; */
  /* font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;  
  scroll-behavior: smooth; */
}

/* Base Navbar Styling */
.nav {
  width: 100%;
  font-family: 'Mulish';
  background-color:#222222;
}

/* Navbar Container */
.navbar {
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 10rem;
  background-color:#222222;
  color:white;

  
}

.navbar-logo img {
  width: 100px;
  height: 80px;
}

.navbar-links {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.navbar-links a {
  color:white;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  /* font-weight: 500; */
}

.navbar-links a:hover {
  /* padding: 10px 10px; */
  /* background:linear-gradient(to bottom, #B94703, #E8A158); */
  /* border-radius: 10px; */
  color: #B94703;
}

.download-brochure {
  padding: 0.5rem 1rem;
  background: linear-gradient(to bottom, #B94703, #E8A158);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  /* font-weight: 500; */
}

/* Hamburger Icon for Mobile */
.hamburger {
  display: none;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  border-radius: 10px;
}

/* Overlay Styles for Mobile Menu */
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(234, 226, 226, 0.9);
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:black;
  transition: opacity 0.3s ease;
}

.overlay.open {
  display: flex;
}

.overlay-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  background: none;
  color: black;
  border: none;
  cursor: pointer;
}

.overlay-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

.overlay-links a {
  color:black;
  font-size: 1.5rem;
  text-decoration: none;
  font-family:'Bai Jamjuree';
  font-weight: 600;
  line-height: 25.1px;

}

/* Responsive Design */


@media (max-width:1024px) {
  .navbar-links {
    display: none; /* Hide navbar links on mobile */
  }

  .hamburger {
    display: flex; /* Show hamburger icon */
  }

  .navbar {
    padding: 1rem ; /* Reduce padding further for mobile */
    justify-content: space-between;
    gap: 37.5rem;
  }

  .navbar-logo {
    flex: 1; /* Ensures logo stays on the left */
  }

  .hamburger {
    flex: 1;
    justify-content: flex-end; /* Align hamburger to the right */
   
    
  }
}




@media (max-width:768px) {
  .navbar-links {
    display: none; /* Hide navbar links on mobile */
  }

  .hamburger {
    display: flex; /* Show hamburger icon */
  }

  .navbar {
    padding: 1rem ; /* Reduce padding further for mobile */
    justify-content: space-between;
    gap: 37.5rem;
  }

  .navbar-logo {
    flex: 1; /* Ensures logo stays on the left */
  }

  .hamburger {
    flex: 1;
    justify-content: flex-end; /* Align hamburger to the right */
   
    
  }
}

/* Mobile Screens (768px and Smaller) */
@media (max-width: 480px) {
  .navbar-links {
    display: none; /* Hide navbar links on mobile */
  }

  .hamburger {
    display: flex; /* Show hamburger icon */
  }

  .navbar {
    padding: 1rem ; /* Reduce padding further for mobile */
    justify-content: space-between;
    gap: 13.5rem;
  }

  .navbar-logo {
    flex: 1; /* Ensures logo stays on the left */
  }

  .hamburger {
    flex: 1;
    justify-content: flex-end; /* Align hamburger to the right */
    
  }
}




/* Base Styles for .quote-section */
.quote-section {
  width: 100%;
  background-image: linear-gradient( rgba(104, 11, 11, 0.692)  , rgb(0,0,0,0.5)), url('../Assests/home.jpg');
  /* background: linear-gradient(to bottom, rgb(249, 241, 234,0.1)  , rgb(234, 234, 234,0.4)); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 3rem 10rem;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 0rem;
}

.quote-content {
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  gap: 2rem;
  box-sizing: border-box;
}

/* Text Styling */
.quote-text {
  flex: 1;
  line-height: 1.6;
}

.quote-subtitle {
  font-size: 60px;
  margin-bottom: 0.5rem;
  font-family:'Bai Jamjuree';
  font-weight: 600;
  line-height: 68.75px;
  color: white;

}

.quote-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;

}

.quote-description {
  font-size: 20px;
  color: #f5f5f5;;
  margin: 1rem 0;
  font-family: 'Mulish';

font-weight: 500;
line-height: 25.1px;
}

.quote-support p {
  font-size: 20px;
  color: #f5f5f5;;
  font-family: 'Mulish';

font-weight: 500;
line-height: 25.1px;
  margin: 0.5rem 0;
}

.quote-phone {
  font-size: 20px;
  color:#B94703;
  /* margin: 1rem 0; */
  font-family: 'Mulish';

  font-weight: 500;
  line-height: 25.1px;
}

/* Form Styling */
.quote-form {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(185, 71, 3, 0.2);
  text-align: left;
  box-sizing: border-box;
  /* border-radius: 8px; */
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.quote-form h2 {
  font-size: 1.8rem;
  color:#fff;
  margin: 0rem 0 2rem;
  font-family:'Bai Jamjuree';
  font-weight: 600;

}

.quote-form label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #fff;
  text-align: left;
  padding: 0;
  font-family: 'Mulish';
  font-weight: 500;
}

.quote-form input,
.quote-form textarea {
  width: 100%;
  max-width: 100%; /* Remove max-width constraint */
  margin: 0 auto 1rem;
  display: block;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.quote-form textarea {
  height: 100px;
  resize: vertical;
}

.quote-button {
  display: block;
  width: 100%; /* Full width on all screens for better alignment */
  padding: 0.8rem 1.5rem;
  background-color: #e67e22;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
  font-family: 'Mulish';
  font-weight: 500;
}

.quote-button:hover {
  background-color: #cf6a1e;
}

/* Responsive Design */

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .quote-section {
    padding: 2rem 1.5rem;
    padding-bottom: 0;
  }

  .quote-content {
    padding: 0 1rem;
  }

  .quote-subtitle {
    font-size: 2rem;
  }

  .quote-text h1 {
    font-size: 2rem;
  }

  .quote-description,
  .quote-support p,
  .quote-phone {
    font-size: 1.2rem;
  }

  .quote-form{
    width: 350px;
  }

  .quote-form h2 {
    font-size: 1.6rem;
  }

  .quote-form {
    padding: 1.0rem;
    margin-bottom: 0;
  }
}

/* Small Screens (Mobile Devices) */
@media (max-width: 768px) {
  .quote-section {
    padding: 1.5rem 5rem;
    padding-bottom: 0;
  }

  .quote-content {
    flex-direction: column;
    align-items: center;
  }

  .quote-text,
  .quote-form {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .quote-text h1 {
    font-size: 2rem;
  }

  .quote-subtitle {
    font-size: 1.6rem;
    line-height:30.75px;
  }

  .quote-description,
  .quote-support p,
  .quote-phone {
    font-size: 1.2rem;
  }

  .quote-form {
    padding: 1.5rem;
  }

  .quote-form h2 {
    font-size: 1.4rem;
  }
}

/* Extra Small Screens (Small Mobile Devices) */
@media (max-width: 480px) {
  .quote-section {
    padding: 1rem 1rem;
    padding-bottom: 0;
  }

  .quote-content {
    padding: 0;
  }

  .quote-text h1 {
    font-size: 1.6rem;
  }

  .quote-subtitle {
    font-size: 1.2rem;
  }

  .quote-description,
  .quote-support p,
  .quote-phone {
    font-size: 0.85rem;
  }

  .quote-form {
    padding: 1rem;
  }

  .quote-form h2 {
    font-size: 1.2rem;
  }

  .quote-button {
    padding: 0.6rem 1rem;
  }
}

 


  .about-section {
    width: 100%;
    background-color:#fff;
  }
  
  .about-content {
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 10rem;
    gap: 50px;
  }
  
  /* Responsive Typography */
  .about-text h2 {
    font-size:40px;
    margin-bottom: 1rem;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
    line-height: 1rem;
  }

  .about-text h3{
    font-size:26px;
    margin-bottom: 3rem;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
    line-height: 1rem;


  }
  
  .about-text p {
    font-size: 1rem;
    color: #555;
    line-height: 24px;
    font-family:'Mulish';
    text-align: right;
  }

  .about .READ{
    border: none;
    font-size: 16px;
    color:black;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
    display:inline-flex;
    margin: 0 auto;
    gap: 10px;
    background-color: #fff;
    cursor: pointer;

  }

  .about .READ img{
    width: 15px;
    padding-top: 3px;  
   
  }
  
  /* Image Container */
  .about-images {
    display: flex;
    flex-direction: column;
    gap: 0;
    position: relative;
    padding: 50px 0;
    /* border:2px solid #B94703; */
    padding-bottom: 0px;
  }
  
  .image-top {
    margin-bottom: -4px; /* Ensures no visible space between images */
    width: 500px;
    height: 500px;
    /* border:2px solid #B94703; */
  }
  
  .image-bottom {
    position: absolute;
    top: calc(100% - 50px); /* Adjust this value for overlap */
    left: 0;
    width: 500px;
    height: 300px;
    z-index: 2;
    /* border:2px solid #B94703; */
  }
  
  /* Responsive Design */
  
  /* Medium Screens (Tablets) */
  @media (max-width: 1024px) {
    .about-content {
      padding: 20px 5rem;
      gap: 30px;
      flex-direction: column;
      align-items: center;
      
    }
  
    .about-text h2 {
      font-size: 2rem;
    }
  
    .about-text p {
      font-size: 1.2rem;
      line-height: 22px;
    }
  
    .image-top {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 10px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }

    .image-bottom {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: -150px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }
  }
  
  /* Small Screens (Mobile Devices) */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
      align-items: center;
      padding: 20px 5rem;
      text-align: center;
    }
  
    .about-text {
      max-width: 90%;
      margin-bottom: 2.0rem;
    }
  
    .about-text h2 {
      font-size: 2.0rem;
    }

    .about-text h3{
      font-size: 1.8rem;
    }
  
    .about-text p {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  
    .about-images {
      padding: 20px 0;
    }
  
    .image-top {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 10px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }

    .image-bottom {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: -150px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }
  }

  
  /* Small Screens (Mobile Devices) */
  @media (max-width: 480px) {
    .about-content {
      flex-direction: column;
      align-items: center;
      padding: 20px 1rem;
      text-align: center;
    }
  
    .about-text {
      max-width: 90%;
      margin-bottom: 1.5rem;
    }
  
    .about-text h2 {
      font-size: 1.5rem;
    }

    .about-text h3 {
      font-size: 1.2rem;
    }
  
    .about-text p {
      font-size: 0.9rem;
      line-height: 1.2;
    }
  
    .about-images {
      padding: 20px 0;
    }
  
    .image-top {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 10px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }

    .image-bottom {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 0px; /* Stack the images vertically on small screens */
      margin-top: 0;
    }
  }
  
  


.service{
    width: 100%;
    background-color: #f5f5f5;
}
  
  /* Next Section Styling */
  .services {
    background-color: #f5f5f5; /* Dark background color for contrast */
    padding: 1rem 10rem;
    position: relative;
    z-index: 1;
    margin-top: 0px; /* Matches overlap adjustment in the .image-bottom */
    padding-bottom: 5rem;
  }
  
  /* Services Section */


.services {
    color:#464444;
    text-align:left;
    max-width: 1520px;
    margin: 0 auto;

  }
  
  .services-header h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    /* font-weight: bold; */
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;

  }
  
  .services-header p {
    font-size: 2rem;
    margin-bottom: 1rem;
    color:#B94703;
    font-weight: bold;
    font-family:'Mulish';


  }
  
  .services-header .underline {
    width: 60px;
    height: 2px;
    background-color: #B94703;
    /* margin: 0 auto ; */
    font-weight: bold;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    /* margin-top: 2rem; */
  }
  
  .service-item {
    border-radius: 8px;
    text-align: left;
    position: relative;
    
  }

  .service-item .num{
    color: #B94703;
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    color:#222222;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
  }
  
  .service-item img {
    width: 100%;
    height:300px;
  }
  
  .service-item p {
    font-size: 1rem;
    color: #222222;
    font-family:'Mulish';
    
  }
  
  .service-item a {
    color:#222222;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;

  }
  
  .service-item a:hover {
    text-decoration: underline;
  }
  

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .services {
    padding: 5rem 5rem; /* Reduced padding for tablet screens */
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Show 2 items per row on tablets */
  }

  .services-header h2 {
    font-size: 2.4rem;
  }

  .services-header p {
    font-size: 1.2rem;
  }

  .service-item h3 {
    font-size: 1.4rem;
  }

  .service-item p {
    font-size: 1.1rem;
  }
}

/* Small Screens (Mobile Devices) */
@media (max-width: 768px) {
  .services {
    padding: 3rem 3rem; /* Further reduced padding for mobile */
    text-align: center; /* Center-align text on mobile for better readability */
    padding-top: 8rem;
  }

  .services-header h2 {
    font-size: 2.4rem;
  }

  .services-header p {
    font-size: 1.6rem;
  }
  .services-header .underline{
    display: block;
    margin: 0 auto;
  }

  .services-grid {
    grid-template-columns: 1fr; /* Show 1 item per row on mobile */
    padding: 0px 5rem;
  }

  .service-item h3 {
    font-size: 1.4rem;
  }

  .service-item p {
    font-size: 1.2rem;
  }
}

/* Extra Small Screens (Small Mobile Devices) */
@media (max-width: 480px) {
  .services {
    padding: 2rem 1rem;
    /* padding-top: 7.5rem; */
  }

  .services-header h2 {
    font-size: 1.5rem;
    text-align:center;
  }

  .services-header p {
    font-size: 1rem;
    text-align:center;
  }

  .services-header .underline{
    display: block;
    margin: 0 auto;
  }

  .services-grid{
    padding: 10px 1rem;
  }

  .service-item h3 {
    font-size: 1.2rem;
  }

  .service-item p {
    font-size: 0.75rem;
  }
}

/* .testimonials{
    width: 100%;

}

.testimonials-section {
    padding: 4rem 2rem;
    background-color: #fff;
    color: #333;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  .testimonials-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .testimonials-header h2 {
    font-size: 2rem;
  }
  
  .testimonials-header p {
    font-size: 1rem;
    color: #555;
    max-width: 600px;
  }
  
  .view-all {
    color: #f57c00;
    font-weight: bold;
    text-decoration: none;
  }
  
  .view-all:hover {
    text-decoration: underline;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .testimonial-item {
    text-align: left;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .testimonial-quote {
    font-size: 2rem;
    color: #ccc;
    margin-bottom: 1rem;
  }
  
  .testimonial-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .testimonial-author {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.2rem;
  }
  
  .testimonial-location {
    color: #777;
    font-size: 0.9rem;
  }
  
  @media (max-width: 1024px) {
    .testimonials-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .testimonials-grid {
      grid-template-columns: 1fr;
    }
  } */


.faq{
    width: 100%;
    background-color: #f5f5f5;
}
  
/* Quick Service Section */
.quick-service-section {
    padding: 0rem 0rem;
    text-align: center;
    max-width: 1520px;
    margin: 0 auto;
    margin-bottom: 0; 
  }
  
  .reviews-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10rem;
    margin-bottom: 0rem;
    padding-bottom: 30px;
    background-color: rgba(254, 129, 55, 1);
    border-radius:60px 60px 0 0;
  }
  
  .reviews-header p {
    font-size: 3rem;
    color:white;
    font-weight: bold;
    margin-bottom: 2rem;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
  }
  
  .reviews-logos {
    display: flex;
    gap: 20rem;
  }
  
  .review-logo {
    width: 180px;
    height: auto;
  }
  
  .quick-service-content {
    display: flex;
    align-items: center; /* Center the content vertically in the container */
    justify-content: space-between;
    background-color:#15141C;
    color: white;
  }
  
  .service-image {
    flex: 1; /* Take full width of the left side */
    display: flex;
    justify-content: center; /* Center the image horizontally within the left side */
    align-items: center; /* Center the image vertically if needed */
  }
  
  .service-image img {
    width: 100%;
    height:400px;
    /* object-fit: cover; */
  }
  
  .service-details {
    flex: 1; /* Take full width of the right side */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically in the right side */
    text-align:left; /* Center-align the text in this section */
    padding: 1rem;
  
  }
  
  .service-details h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
  }
  
  .service-details .underline {
    width: 60px;
    height: 2px;
    background-color:#B94703;
    /* margin: 0.5rem auto;  */
  }
  
  .contact-number {
    font-size: 2.5rem;
    color:#B94703;
    margin-top: 1rem;
    font-family:'Mulish';
  }
  
  
  /* Responsive Design */

  
  @media (max-width:1024px) {

    .quick-service-content {
      flex-direction:row;
      text-align: center;
    }
  
    .service-details {
      text-align: center;
      max-width: 100%;
      
    }

    .service-details .underline{
      display: block;
      margin: 0 auto;
    }

    .service-details .contact-number{
      font-size: 20px;
    }

    .service-details h2{
      font-size: 24px;
    }

    .reviews-header{
      margin: 0px 4rem;
    }
  
    .reviews-logos {
      flex-direction:row;
      gap: 5rem;
    }
  }


  @media (max-width: 768px) {

    .quick-service-content {
      flex-direction:row;
      text-align: center;
    }
  
    .service-details {
      text-align: center;
      max-width: 100%;
      
    }
    .reviews-header{
      margin: 0px 4rem;
    }

    .service-details .underline{
      display: block;
      margin: 0 auto;
    }

    .service-details .contact-number{
      font-size: 20px;
    }

    .service-details h2{
      font-size: 24px;
    }
  
    .reviews-logos {
      flex-direction:row;
      gap: 5rem;
    }
  }


  @media (max-width: 480px) {
    .quick-service-content {
      flex-direction: column;
      text-align: center;
    }
  
    .service-details {
      text-align: center;
      max-width: 100%;
    
    }

    .reviews-header{
      margin: 0px 2rem;
    }

    .reviews-header p{
      font-size: 24px;
    }

    .service-details .underline{
      display: block;
      margin: 0 auto;
    }

    .service-details .contact-number{
      font-size: 20px;
    }

    .service-details h2{
      font-size: 20px;
    }
  
    .reviews-logos {
      flex-direction: column;
      gap: 1rem;
    }

    .reviews-logos img{
      width: 150px;
    }
  }



/* Footer Section */

.contact{
    width: 100%;
    background-color:#222222 ;
    font-family:'Mulish';
}

.footer-section {
    max-width: 1520px;
    margin: 0 auto;
    background-color:#222222;
    color: #ccc;
    padding: 3rem 2rem;
    font-size: 0.9rem;
    margin-top: 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 2rem;
    text-align: left;
  }
  
  .footer-column h3, .footer-column h4 {
    color: #fff;
    margin-bottom: 1rem;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
  }
  
  .footer-column p {
    color:#fff;
    margin-bottom: 1rem;
  }

  .policies{
    display: flex;
    flex-direction:row;
    gap: 20px;
    font-family: 'Bai Jamjuree',sans-serif;
    font-weight: 600;
    
  }

  .policies a{
    text-decoration-line: none;
    color:#fff;
  }

  .policies a:hover{
    transform: translateY(-3px);
  }

  
  .footer-phone {
    font-weight: bold;
    color:#B94703;
  }
  
  .footer-column .underline {
    width: 50px;
    height: 2px;
    background-color:#B94703;
   
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #333;
    padding-top: 1.5rem;
    margin-top: 2rem;
    font-size: 0.8rem;
    color:#fff;
  }
  
  
  /* Responsive Design */

  @media (max-width: 1024px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
      font-size:24px ;
    }
  
    .footer-bottom {
      flex-direction: column;
      gap: 1rem;
    }
  }


  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
      font-size:24px ;
    }
  
    .footer-bottom {
      flex-direction: column;
      gap: 1rem;
    }

    .footer-column .underline{
      display: block;
      margin: 0 auto;
    }

    .policies{
      align-items: center;
      justify-content: center;
    }
  }

  
  /* Responsive Design */
  @media (max-width: 480px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
      font-size: 16px;
    }
  
    .footer-bottom {
      flex-direction: column;
      gap: 1rem;
    }
    .footer-column .underline{
      display: block;
      margin: 0 auto;
    }
  }
  
  